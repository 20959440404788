@import 'variables';

.grid-view {
  margin-bottom: 10px;
  // overflow: hidden; /* CANNOT be overflow hidden unless all fly-out menus exist outside of this element */
  border-radius: 4px;
  box-shadow: 0 0 12px rgba(103, 103, 103, 0.4);

  table.data-table {
    width: 100%;

    thead {
      th > button {
        text-align: left;
        padding-left: 0; //to align with left-aligned td content
        &.sort {
          &.sort-asc:after {
            border-top-color: $white;
          }

          &.sort-desc:before {
            border-bottom-color: $white;
          }
        }
      }

      tr {
        background-color: $white;
        border-bottom: 3px solid $chr-light-grey;

        button {
          padding-right: 12px;
          position: relative;
          color: #363636;
          transition: color .2s ease-out, background 0.5s ease-in;

          &:hover {
            text-decoration: none;
            color: $chr-active-blue;
          }

          &:before, &:after {
            border: 4px solid transparent;
            content: "";
            display: block;
            height: 0;
            right: 0;
            top: 50%;
            position: absolute;
            width: 0;
          }

          &:before {
            border-bottom-color: $chr-medium-grey;
            margin-top: -9px;
          }

          &:after {
            border-top-color: $chr-medium-grey;
            margin-top: 1px;
          }
        }
      }
    }

    th, td {
      padding: $space-sm;
      line-height: 1.25;
    }

    th {
      white-space: nowrap;
    }

    tbody {
      td {
        vertical-align: top;

        a, a:link, a:visited, a:hover {
          &:not(.btn-primary) {
            color: $chr-active-blue;
          }
        }
      }

      tr {
        background-color: $table-grid-light-grey;

        &:not(:last-child) {
          border-bottom: 1px solid $table-grid-light-grey;
        }

        &:nth-child(odd) {
          background-color: #FFF
        }
      }

      //FIND LOCATION, DRIVER, AND NO-WRAP IN TABLES AND MAKE SURE THEY DONT WRAP
      .assign-driver {
        text-decoration: none;
        text-align: center;
        display: block;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

}
