@import 'variables';
@import 'media-queries';

/*---Start of placeholder classes--*/
%link {
  color: $button-primary-blue;
  font-size: $font-size-sm;
  font-weight: $bold;
  cursor: pointer;

  svg {
    fill: $button-primary-blue;
    margin-right: $space-xs;
    vertical-align: middle;
  }

  &:hover, &:focus {
    svg {
      fill: #004362;
    }
  }

  &:disabled, &.disabled {
    color: $button-disabled-grey;
  }
}
// We apply the .btn to all of our buttons to allow us the flexibility of not needing to 'undo' our button styles every time we need a button that has no styling.
%btn {
  font-size: $font-size-sm;
  font-weight: $bold;
  min-height: 44px; // minimum target size accourding to accessibility guides
  padding: ($line-height-scale * 0.5) + rem; // SASS syntax to assign value unit measure
  white-space: normal;
  border: 1px solid transparent; //transparent border on all .btns to help align inline elements
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;

  &:focus {
    outline: 1px dotted $button-primary-blue;
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:hover, &:focus {
    text-decoration: none;
  }
}

@mixin default-button { // This style is defined as mixin to support the use of media queries.
  border-color: $button-primary-blue;
  color: $button-primary-blue;
  background-color: $white;
  outline: 0 !important;
  box-shadow: none !important;

  svg {
    fill: $button-primary-blue;
    margin-right: $space-xs;
    vertical-align: middle;
  }

  &:hover, &:focus {
    background-color: rgba(0, 0, 0, 0.08) !important;
    border-color: #004986 !important;
    color: #004986 !important;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.2) !important;
    border-color: #004986 !important;
    color: #004986 !important;
  }

  &:disabled {
    background-color: #fff !important;
    color: $button-disabled-grey !important;
    border-color: rgba(0, 0, 0, 0.26) !important;
  }
  
}

%default-button {
  @include default-button;
}

@mixin primary-button { // This style is defined as mixin to support the use of media queries.
  border-color: $button-primary-blue;
  color: $white;
  background-color: $button-primary-blue;
  outline: 0 !important;
  box-shadow: none !important;

  svg {
    fill: $white;
    margin-right: $space-xs;
    vertical-align: middle;
  }

  &:hover, &:focus {
    background-color: #004986;
  }

  &:active {
    background-color: #003A6B;
  }
}

%primary-button {
  @include primary-button;
}

%clear-button { //clears styles of button. Meant for icon buttons.
  @extend %btn;
  padding: 0;
  background: transparent;
  border-color: transparent;
  min-height: unset;
}

/*---End of placeholder classes--*/

/*--- button and link Styling---*/
a {
  @extend %link;
}

.btn, a.btn, button.btn {
  @extend %btn;
}

.btn-default, .btn.btn-default {
  @extend %default-button;
}

.btn-primary, .btn.btn-primary {
  @extend %primary-button;
}

.btn-link, button.btn-link {
  @extend %link;
}

.btn-clear, .btn.btn-clear {
  @extend %clear-button;
}

/*---End of button and link styling---*/

/*---autoStyle/bootstrap overrides---*/
button:not(.btn).btn-link {
  padding: 0;
  border: 0;
}

.btn:not(:disabled):not(.disabled).btn-default {
  border: 1px solid $chr-active-blue;
}

.btn:disabled,
.btn:disabled:hover,
.btn:disabled:focus,
.btn:disabled.focus,
.btn:disabled:active,
.btn:disabled.active,
.btn:disabled.shown,
.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus,
.btn.disabled.focus,
.btn.disabled:active,
.btn.disabled.active,
.btn.disabled.shown {
  background: rgba(0, 0, 0, 0.12);
  color: $button-disabled-grey;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.btn:not(:disabled):not(.disabled):hover,
.btn:not(:disabled):not(.disabled):focus {
  border: 1px solid transparent;
  transition: unset;

  &.btn-default {
    background-color: $navisphere-overlay-blue;
  }
}

.btn:not(:disabled):not(.disabled):hover,
.btn:not(:disabled):not(.disabled):focus,
.btn:not(:disabled):not(.disabled).focus,
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled).shown {
  text-shadow: none;
}

/*---end of autoStyle/bootstrap overrides---*/

/*---button grouping responsive behavior---*/
//base mobile button styling
%mobile-button-styling {
  @include respond-below(xxs) {
    width: 100%;
    display: block;
    padding-top: $space-sm;
    padding-bottom: $space-sm;
  }
}

//for a stand-alone button
button.mobile-responsive, a.mobile-responsive {
  @extend %mobile-button-styling;
}

//for grouping multiple buttons
.button-bay_mobile-responsive {
  button, a {
    margin-bottom: $space-xs; //providing small amount of padding below incase they wrap before our xs breakpoint
    @extend %mobile-button-styling;
  }

  &:not(.text-right) {
    button:not(:last-child), a:not(:last-child) {
      margin-right: $space-sm; //default for buttons is to be left aligned. Keeping margin on right to facilitate stacking behavior
    }
  }

  &.text-right {
    button:not(:first-child), a:not(:first-child) {
      margin-left: $space-sm;
    }
  }

  @include respond-below(xxs) {
    button, a {
      margin-bottom: $space-md;
    }

    &:not(.text-right) {
      button, a {
        &:last-child {
          margin-bottom: 0;
        }
      }

      button:not(:last-child), a:not(:last-child) {
        margin-right: 0;
      }
    }

    &.text-right {
      display: flex;
      flex-direction: column-reverse; //puts primary button (which should always be the right-most button) as the first button when this stacks.

      button, a {
        &:first-child { //since use column-reverse the element that visually looks last is actually the first listed in the html.
          margin-bottom: 0;
        }
      }

      button:not(:first-child), a:not(:first-child) {
        margin-left: 0;
      }
    }
  }
}

/*---end of button responsive behavior---*/
