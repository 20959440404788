@import 'variables';
@import 'media-queries';

$card-gutter: 20px;
$card-border: 20px;
$text-drop: 5px;
$content-drop: 15px;

.ns-card.standard-card {
  padding: 0; //over-ride of autoStyle's 20px padding.
  display: flex;
  margin-top: $content-drop;

  &:not(:first-of-type) {
    margin-top: 20px; //margin between cards
  }

  .card-content {
    padding: $card-border;
    
    > :not(:last-child) {
      margin-right: $card-gutter;
    }

    .data-label-pair:not(:first-child) {
      margin-top: $text-drop;
    }

    .data-group {
      min-width: 70px;
      max-width: 175px;

      .data-label-pair .data {
        word-break: break-word;
      }
    }

    .origin-destination {
      h5 {
        text-transform: uppercase;
        font-weight: 400;
        color: $chr-medium-grey;
        margin-bottom: 0;
      }

      .block {
        background: $carrier-off-white;
        padding: 10px 20px 20px 20px;

        .data {
          word-break: break-word;
        }
      }

      .arrow {
        display: flex;
        align-items: center;
        padding: 0 5px;

        img {
          height: 20px; //Keep IE from stretching svg
        }
      }
    }
  }
  //Optional card features
  &.card-tray {
    display: block;
    padding: $card-border;
    margin-top: 0;
  }

  .btn-block {
    padding-left: 10px;
    padding-right: 10px;
  }

  .include-button-bay {
    display: flex;
    width: 100%;
  }

  .button-bay {
    margin: $card-border $card-border $card-border 0;
    text-align: right;
    display: flex;
    flex-direction: column;

    .call-out {
      margin-bottom: 5px;
      margin-left: auto;

      &.width-auto {
        width: auto;
      }

      h4 {
        margin: 0;
      }
    }

    button, .btn {
      margin-top: 0;
      margin-bottom: 5px;
    }

    .bottom-align {
      margin-top: auto;
      text-align: right;
    }
  }

  .card-bar {
    background: $carrier-off-white;

    .checkbox-container {
      margin: 25px 10px 0 10px;
    }
  }
  //End of Optional card features
  //Responsive breaks for cards.
  @include respond-above(xxs) {
    .card-content {
      .origin-destination {
        display: flex;
      }

      .OD-short-pair {
        .short-content {
          display: flex;
        }
      }
    }
  }

  @include respond-above(xs) {
    .card-content {
      display: flex;
      width: 100%;

      > :not(:last-child) {
        margin-right: $card-gutter;
      }

      .origin-destination {
        .block {
          width: 25vw;
        }
      }

      .OD-short-pair .short-content {
        display: flex;
      }
    }
  }

  @include respond-above(sm) {
    .card-content {
      .origin-destination {
        margin-right: $card-gutter;

        .block {
          width: 14vw; //setting width to ensure card's blocks are always the same size
          max-width: 175px; //ensures blocks won't get too large at larger screen sizes.
        }
      }

      .OD-short-pair {
        display: flex;
      }
    }
    //Optional card features
    .button-bay {
      align-items: flex-end; //all elements in button bay will line up with end of card.
      h4 {
        display: block;
      }

      .call-out {
        min-width: 135px; //Without this style and without other content to expand the 'button-bay' text will wrap on spaces/whitespace. Including a min-width to ensure short names like 'Stacey Jane" don't wrap.
      }

      button, .btn {
        width: 135px; //all buttons in button bay must be the same length when stacked on top of each other.
        white-space: pre-line; //If buttons can only be so long, we should make sure to wrap their content
        display: inline-block; // To center align text we need this display value
        text-align: center; // centering text since our 'natural' button is centered by having consistent padding. Need to center like this if we are setting the width.
      }
    }
    //end of optional card features
  }

  @include respond-above(md) {
    .card-content {
      .long-content {
        display: flex;
      }
    }
  }

  @include respond-between(sm, md) {
    .card-content {
      .origin-destination {
        .block {
          width: 16vw; //setting width to ensure card's blocks are always the same size
        }
      }

      .OD-short-pair {
        .short-content {
          display: block;

          .data-group {
            &:not(:first-child) {
              margin-top: $text-drop;
            }
          }
        }
      }
    }
  }

  @include respond-between(xs, md) {
    .card-content {
      .long-content {
        .data-group {
          &:not(:first-child) {
            margin-top: $text-drop;
          }
        }
      }
    }
  }

  @include respond-below(sm) {
    & {
      display: block;
    }

    .card-content {
      .OD-short-pair {
        display: block;

        .short-content {
          margin-top: $content-drop;
        }
      }
    }
    //Optional card features
    .button-bay {
      margin: 0 $card-border $card-border $card-border;
      flex-direction: row-reverse;
      flex-wrap: wrap;

      .call-out {
        width: 100%;
      }

      button, .btn {
        width: auto;
        display: inline;

        &:not(:last-child) {
          margin-left: 5px;
        }
      }

      .bottom-align {
        margin-right: auto;
      }
    }

    .card-bar {
      display: flex;
      justify-content: space-between;

      .checkbox-container {
        margin: auto 15px;
        padding: 10px;
      }
    }

    .include-button-bay {
      display: block;
    }
    //end of optional card features
  }

  @include respond-between(xs, sm) {
    .card-content {
      .OD-short-pair {
        .short-content {
          & > * {
            width: 100%;

            &:first-child {
              margin-right: 35px;
            }
          }
        }
      }
    }
  }

  @include respond-below(xs) {
    .card-bar {
      .checkbox-container {
        display: none;
      }
    }
  }

  @include respond-between(xxs, xs) {
    .card-content {
      .data-group {
        width: 100%;
      }

      .OD-short-pair {
        .origin-destination {
          .block {
            width: 50%;
          }
        }

        .short-content {
          .data-group {
            .data-label-pair:not(:first-child) {
              margin-top: $text-drop;
            }
          }
        }
      }

      .long-content {
        display: flex;
        margin-top: $text-drop;
      }
    }
  }

  @include respond-below(xxs) {
    .card-content {
      > :not(:last-child) {
        margin-right: 0;
      }

      .data-group {
        &:not(:first-child) {
          margin-top: $text-drop;
        }
      }

      .origin-destination {
        .arrow {
          display: none;
        }
      }

      .long-content {
        margin-top: $text-drop;
      }
    }
    //Optional card features
    .button-bay {
      text-align: center;

      .call-out {
        width: 100%;

        .width-auto {
          width: 100%;
        }
      }

      button, .btn {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 10px;
          margin-left: 0;
        }
      }

      .bottom-align {
        margin-left: auto;
      }
    }
    //end of optional card features
  }
  //end of responsive breaks for cards
  //IE 10+ styling
  //IE doesn't calc width with flexbox well, to prevent strange behavior IE will always have the button bay at the bottom of the card
  //For IE we will also force the 'card-bar' to be at the top of the card.
  @include browser-only('IE-only') {
    & {
      display: block;
    }

    .button-bay {
      margin: 0 $card-border $card-border $card-border;
    }

    .action-menu {
      display: none;
    }

    .card-bar {
      display: flex;
      justify-content: space-between;

      .checkbox-container {
        margin: auto 15px;
        padding: 10px;
      }

      .action-menu {
        display: block;

        .glyphicon-option-vertical {
          padding: 10px 15px;
        }
      }
    }
  }

  @include respond-above(sm, IE-only) {
    & {
      display: block;
    }

    .button-bay {
      flex-direction: row-reverse;
      flex-wrap: wrap;
      width: auto;

      .call-out {
        width: 100%;
      }

      button, .btn {
        width: auto;
        display: inline;
        margin-bottom: 0;

        &:not(:last-child) {
          margin-left: 5px;
        }
      }

      .bottom-align {
        margin-right: auto;
      }
    }
  }
  //End of IE styling
  //Card Styles for simple card with similar functionality to original card
  & .card-content.simple {
    .origin-destination {
      .block {
        width: auto;
      }
    }

    .data-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .data-label-pair {
        margin-top: 0;
        margin-right: 15px;
      }
    }

    @include respond-above(xs) {
      .data-container .data-label-pair {
        margin-right: 10%;
      }
    }

    @include respond-between(xs, sm) {
      .origin-destination {
        margin-right: $card-gutter;
      }
    }

    @include respond-below(xs) {
      .data-container .data-label-pair {
        margin-top: $content-drop;
      }
    }

    @include respond-below(xxs) {
      .data-container {
        justify-content: unset;
      }
    }
  }
  //end of simple card styling
}

.ns-card.flow-card {
  &:not(:first-child) {
    margin-top: $space-sm;
  }

  .card-header_button-bay {
    text-align: right;
  }

  .river {
    display: flex;
    flex-wrap: wrap;

    .data-label-pair {
      .data {
        word-break: break-word;
        hyphens: auto;
      }
    }
  }

  .footer {
    b {
      font-size: $font-size-lg;
    }

    .button-bay {
      button, .btn {
        width: 135px; //all buttons in button bay must be the same length when stacked on top of each other.
        white-space: pre-line; //If buttons can only be so long, we should make sure to wrap their content
        display: inline-block; // To center align text we need this display value
        text-align: center; // centering text since our 'natural' button is centered by having consistent padding. Need to center like this if we are setting the width.
      }
    }
  }

  @include respond-above(xs) {
    display: flex;
    justify-content: space-between;
    padding-bottom: $space-xs; //smaller card padding so data-label-pair padding doesn't make extra space

    .card-header_button-bay {
      margin-right: $space-lg;
    }

    .river {
      width: 100%;

      .data-label-pair {
        width: 18%;
        margin-right: $space-md;
        margin-bottom: $space-md;

        &.full-width {
          width: 100%;
          margin-right: 0;
        }
      }
    }

    .footer {
      width: 20%;
      min-width: 135px; //needs to be at least at big as buttons in button bay
      text-align: right;

    }
  }
  @include respond-below(xs) {
    padding: $space-md;

    .card-header_button-bay {
      margin-bottom: $space-md;
    }

    .river {
      .data-label-pair {
        width: 47%;
        margin-bottom: $space-lg;

        &:nth-of-type(2n) {
          margin-left: 6%;
        }

        &.full-width {
          width: 100%;
          margin-left: 0;
        }
      }
    }

    .footer {
      margin-top: $space-sm;
      margin-bottom: $space-sm;
      text-align: center;

      .button-bay {
        button, .btn {
          width: 100%;
        }
      }
    }
  }

  @include browser-only('IE-only') {
    .river {
      .data-label-pair {
        .data {
          hyphens: auto; //only way to get data to wrap in IE11
        }
      }
    }
  }
}

.ns-card.status-border {
  @include respond-above(sm) {
    border-left-width: 5px;
    border-left-color: $carrier-mid-1-grey;
  }

  @include respond-below(sm) {
    border-top-width: 5px;
    border-top-color: $carrier-mid-1-grey;
  }

  &.error {
    @include respond-above(sm) {
      border-left-width: 5px;
      border-left-color: $chr-error-red;
    }

    @include respond-below(sm) {
      border-top-width: 5px;
      border-top-color: $chr-error-red;
    }
  }

  &.warning {
    @include respond-above(sm) {
      border-left-width: 5px;
      border-left-color: $warning-color;
    }

    @include respond-below(sm) {
      border-top-width: 5px;
      border-top-color: $warning-color;
    }
  }

  &.success {
    @include respond-above(sm) {
      border-left-width: 5px;
      border-left-color: $success-color;
    }

    @include respond-below(sm) {
      border-top-width: 5px;
      border-top-color: $success-color;
    }
  }

  &.info {
    @include respond-above(sm) {
      border-left-width: 5px;
      border-left-color: $info-color;
    }

    @include respond-below(sm) {
      border-top-width: 5px;
      border-top-color: $info-color;
    }
  }
}
