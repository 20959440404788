@import 'variables';
@import 'buttons';
@import 'media-queries';

.results-scrollable {
  overflow-x: auto;
}

.sort-order-toggle {
  display: flex; //centers svg
  min-width: 100px; // setting min-width will make sure component width wont resize between states (and leaving room for it to do so it translation is long)
  color: $carrier-mid-3-grey;
  background: $white no-repeat 5px 50%;
  cursor: pointer;
  margin: 0 16px;
  border: 1px solid #a9a9a9; //match with bootstrap/autostyle inputs
  border-radius: 2px 2px 2px 2px;
  line-height: 32px;

  svg {
    height: 16px;
    width: 16px;
    fill: $carrier-mid-3-grey;
    vertical-align: middle;
    margin: 8px 4px 0 0;
  }

  &:focus { //focus state not showing on firefox
    outline: 1px solid $chr-active-blue;
  }
}

.view-selectors {
  .selector:first-of-type {
    input[type="radio"] + label {
      border-radius: 2px 0 0 2px;
    }

    input[type="radio"]:not(:checked) + label {
      border-right: 0;
    }
  }

  .selector:last-of-type {
    input[type="radio"] + label {
      border-radius: 0 2px 2px 0;
    }

    input[type="radio"]:not(:checked) + label {
      border-left: 0;
    }
  }

  .selector {
    input[type="radio"] + label {
      color: $carrier-mid-3-grey;
      background: $white no-repeat 5px 50%;
      cursor: pointer;
      text-align: center;
      font-weight: 400;
      min-width: 60px;
      margin-bottom: 0; //bootstrap overrride
      border: 1px solid $carrier-light-2-grey;
      border-radius: 0;

      svg {
        height: 14px;
        width: 14px;
        margin-bottom: $space-xxs;
        fill: $carrier-mid-3-grey;
        vertical-align: middle;
        margin-right: 4px;
      }
    }

    input[type="radio"]:focus + label {
      outline-color: Highlight;
      outline-style: solid;
      outline-width: 1px;
      @media (-webkit-min-device-pixel-ratio:0) { //only chrome doesn't read 'auto' as 'none', this is how we mimic Chrome's focus style
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
      }
    }

    input[type="radio"]:checked + label {
      cursor: default;
      border: 1px solid #a9a9a9; //match with bootstrap/autostyle inputs
    }
  }
}

.button-control {
  font-size: $font-size-sm;
  padding: 0;
  height: 2rem;

  svg {
    fill: $chr-active-blue;
    height: 19px;
    width: 19px;
    margin-right: 4px;
    vertical-align: middle;

  }

  &:hover {
    svg {
      fill: #004362;
    }
  }
}

.data-table-component {
  .grid-view-hidden {
    display: none;
    @include respond-below(md) {
      display: block;
    }
  }

  select#page-size, .sort-order-toggle, .selector label, .card-sorting select, .filter-by { //Aesthetically, all of these should be the same height
    height: 2rem;
  }

  select#page-size, .sort-order-toggle, .selector label, .card-sorting select { //Aesthetically, all of these should be the same padding
    padding: 0 6px;
  }
}

.table-header { //header that holds grid/card toggle, pagination details, and results per page select
  margin: 8px 0;

  &.hideControls {
    margin: 0;
  }

  .notify-me > button {
    height: 32px;
  }

  &.desktop-view {
    display: flex;
    align-items: flex-end;

    .button-control {
      margin-left: $space-md;
    }

    .notify-me {
      padding-left: 15px;
    }
  }

  &.mobile-view {
    display: none;

    .button-control {
      @extend %btn;
      @extend %default-button;
    }

    .align-center {
      align-items: center;
    }

    .notify-me {
      width: 100%;
    }
  }

  .button-bay {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .content-bay{ 
    line-height: 32px;
  }

}

.table-container{
  &.has-detail {
    display: grid;
    gap: 4px;

    @include respond-above(lg_two_columns_cuttoff) {
      grid-template-columns: 1fr 394px;
    }
    @include respond-above(xl) {
      grid-template-columns: 1fr 480px;
    }
  }

  .table-content-detail{
    overflow-y: auto;

    @include respond-below(lg_two_columns_cuttoff) {
      display: none;
    }
  }
}

@include respond-above(sm) {
  .table-footer {
    display: flex;
    margin: $space-md 0;

    .below-results-container {
      text-align: left;
      width: 100%;
      &.mobile {
        display: none;
      }
    }

    .pagination-container {
      text-align: center;
      margin-left: $space-xl;
      margin-right: $space-xl;

      .pagination-component {
        min-width: 20.3rem; // enlarges as font enlarges
      }
    }

    .page-size-container {
      text-align: right;
      width: 100%;
    }
  }
}

@include respond-below(md) { //at this screen size we force card-view
  .table-header {
    flex-wrap: wrap;

    .view-selectors {
      display: none;
    }

    &.desktop-view {
      .content-bay, .notify-me {
        margin-bottom: $space-sm;
      }

      .button-bay {
        flex-wrap: wrap;
        justify-content: flex-end;

        & > * {
          margin-top: $space-xs;
        }
      }
    }
  }


  .table-container {
    .grid-view-with-card {
      display: none;
    }

    .card-view-component.grid-view-hidden {
      display: block;
    }
  }

  .table-footer {
    .pagination-container {
      margin-left: $space-md;
      margin-right: $space-md;
    }
  }
}

@include respond-below(sm) {
  .table-footer {
    margin-top: 24px;
    margin-bottom: 24px;

    .below-results-container, .pagination-container, .page-size-container {
      text-align: center;
    }

    .page-size-container {
      margin-top: $space-md;
    }

    .below-results-container {
      display: none;

      &.mobile {
        display: block;
        margin-top: 24px;
      }
    }

    .page-size {
      margin-top: $space-md;
    }
  }
}

@include respond-below(xs) {
  .table-header {
    &.desktop-view {
      display: none;
    }

    &.mobile-view {
      display: block;

      .sort-order-toggle, .card-sorting select, .pagination-range, .button-control {
        width: 100%;
        height: 40px;
      }
    }
  }
}

/*Start of table button/checkbox styles*/
%table-button {
  padding: $space-xs $space-sm;
  font-size: $font-size-sm;
  line-height: 1.5;
  width: 100%; //makes all buttons in column same size (size of their column)
  max-width: 150px;
  border: 1px solid transparent;
  text-align: center;
  white-space: nowrap;
}

.data-table {
  td {
    button.btn-primary, button.btn-default { //making all button-like elements display the same in tables
      @extend %table-button;
    }

    a:not(.btn-primary), .btn-link, .btn.btn-link, .btn.btn-clear {
      &:not(.error-popover-trigger) {
        @extend %table-button;
        padding-top: 0; //top padding is 0 to align with table content
        width: auto; //left aligns ink and makes it have smaller clickable area
      }
    }
  }

  input[type=checkbox] {
    background: pink;
    &.all-selected::before {
      content: '\2714';
    }

    &.some-selected::before {
      content: '\2013';
      font-size: 20px;
      line-height: .6;
      padding-left: 2px;
      color: $chr-active-blue;
      border-color: $chr-active-blue;
    }

    &.none-selected::before {
      content: '';
    }
  }
}

/*End of table button/checkbox styles*/
