//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

/* stylelint-disable */
$breakpoints: (
  xxs: 420px,
  xs: 575px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  lg_two_columns_cuttoff: 1281px,
  xl: 1400px,
  xxl: 1600px
);

$browsers: (
  IE-only: "(-ms-high-contrast: none), (-ms-high-contrast: active)"
);
/* stylelint-enable */

@function getValue($map, $value) {
  @if type-of($map) != 'map' {
    @error 'Invalid map: #{map}. Check _media-queries.scss file to reference valid maps';
  }
  @if map-has-key($map, $value) {
    @return map-get($map, $value)
  }
  @error 'Invalid value: #{value}. Check _media-queries.scss file to reference valid values';
}

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin respond-above($breakpoint, $browser: null) {
  $breakpoint-value: getValue($breakpoints, $breakpoint);
  @if $browser == null {
    @media (min-width: $breakpoint-value) {
      @content;
    }

  } @else {
    $browser-value: getValue($browsers, $browser);
    @media (min-width: $breakpoint-value) and #{$browser-value} {
      @content;
    }
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin respond-below($breakpoint, $browser: null) {
  $breakpoint-value: getValue($breakpoints, $breakpoint);
  @if $browser == null {
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

  } @else {
    $browser-value: getValue($browsers, $browser);
    @media (max-width: ($breakpoint-value - 1)) and #{$browser-value} {
      @content;
    }
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin respond-between($lower, $upper, $browser: null) {
  $lower-breakpoint: getValue($breakpoints, $lower);
  $upper-breakpoint: getValue($breakpoints, $upper);

  @if $browser == null {
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    $browser-value: getValue($browsers, $browser);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) and #{$browser-value} {
      @content;
    }
  }
}

@mixin respond-mobile-only() {
  @media (pointer: coarse), (hover: none), (hover: on-demand)  {
    @content;
  }
}

//
//  BROWSER ONLY
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin browser-only($browser) {
  $browser-value: getValue($browsers, $browser);
  @media #{$browser-value} {
    @content;
  }
}
